<template>
  <div class="flex">
    <button
      class="btn btn--secondary btn--minus btn--md !border-r-0 !border-t !border-b !border-l !border-light !bg-lightest"
      :class="{
        touchOnly: !showButtonsOnDesktop,
        disabled: loading,
      }"
      @click="change(-1)"
    />
    <div>
      <div v-if="loading && noInputField" class="miniNumber">
        <LoadingIcon />
      </div>
      <div v-else-if="noInputField" class="miniNumber !text-center">{{ value }}</div>
      <input
        v-else
        :id="id"
        v-model="value"
        type="number"
        class="miniNumber"
        :class="{
          hideBrowserUpDownButtons: showButtonsOnDesktop
        }"
        min="1"
        :tabindex="(index * 10) +1"
        v-bind="attr"
        :disabled="loading"
        @keydown.enter="emit('enter')"
        @blur="emit('blurred')"
        @keyup="emit('change')"
        @change="emit('change')"
      >
    </div>
    <button
      class="btn btn--secondary btn--plus btn--md !border-r !border-t !border-b !border-l-0 !border-light !bg-lightest"
      :class="{
        touchOnly: !showButtonsOnDesktop,
        disabled: loading,
      }"
      @click="change(1)"
    />
  </div>
</template>

<script setup lang="ts">
const value = defineModel<number>({ required: true });
const props = defineProps<{
  index: number,
  id: string,
  attr?: {
    [key: string]: string | number;
  };
  showButtonsOnDesktop?: boolean;
  noInputField?:boolean;
  loading?:boolean;
}>();

const emit = defineEmits<{
  (e: 'enter'): void;
  (e: 'blurred'): void;
  (e: 'change'): void;
}>();

const change = (dir: number) => {
  const newNumber = value.value + dir;
  if (typeof props.attr?.max === 'number' && newNumber > props.attr.max) {
    return;
  }

  const min = typeof props.attr?.min === 'number' ? props.attr.min : 1;
  if (newNumber >= min) {
    value.value = newNumber;
  }
  emit('change');
};

</script>

<style scoped lang="postcss">
.miniNumber {
  @apply border border-light leading-single inline-block p-8 h-36 hover:border-dark focus:border-darkest rounded-0;
}

@media (pointer: coarse) { /*  touch */
  .miniNumber {

    @apply w-48 text-center;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply hidden;
    }
  }
}

@media (pointer: fine) {
  .miniNumber {
    @apply w-64;
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      @apply opacity-100;
    }
  }
  .touchOnly {
    @apply hidden;
  }
}

.hideBrowserUpDownButtons {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    @apply opacity-100;
  }
}

</style>
